<template>
    <div class="error" v-if="error">
        <img src="./assets/logo.png" class="logo">
        <img src="./assets/nocontent.png" class="nocontentimg">
        <div class="appointmentInfo" v-if="data.date"><img src="./assets/calendar.svg" class="icon"> {{date}} - <img src="./assets/clock.svg" class="icon"> {{hour}}</div>
    </div>
    <div v-else>
        <img src="./assets/logo.png" class="logo">
        <div id="otEmbedContainer" :class="isMobile?(portrait?'portraitView':'landscapeView'):''" style="width: 100vw;height: 100vh;"></div>
    </div>
</template>

<script>
import moment from 'moment';
import { mapState } from 'vuex';

const camLogo = '<svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 48 48" width="96px" height="96px"><path fill="#fff" d="M25.8,15.6H14.2c-2,0-3.7,1.7-3.7,3.7v9.5c0,2,1.7,3.7,3.7,3.7h11.6c2,0,3.7-1.7,3.7-3.7v-9.5	C29.5,17.2,27.8,15.6,25.8,15.6z"/><path fill="#fff" d="M34.9,16.4L31,19.8c-0.2,0.2-0.4,0.5-0.4,0.8v6.7c0,0.3,0.1,0.6,0.4,0.8l3.9,3.5c0.9,0.8,2.4,0.2,2.4-1.1V17.5	C37.3,16.2,35.9,15.5,34.9,16.4z"/><path fill="#0f0" d="M35,6H13c-3.9,0-7,3.1-7,7v22c0,3.9,3.1,7,7,7h22c3.9,0,7-3.1,7-7V13C42,9.1,38.9,6,35,6z M29,28.5	c0,1.9-1.6,3.5-3.5,3.5h-11c-1.9,0-3.5-1.6-3.5-3.5v-9c0-1.9,1.6-3.5,3.5-3.5h11c1.9,0,3.5,1.6,3.5,3.5V28.5z M37,29.9	c0,1.1-1.3,1.7-2.2,1l-3.5-3.1C31.1,27.6,31,27.3,31,27v-6c0-0.3,0.1-0.6,0.3-0.7l3.5-3.1c0.8-0.7,2.2-0.2,2.2,1V29.9z"/></svg>';

export default {
    name: 'App',
    computed: {
        ...mapState(['data']),
        date(){
            return this.data.date?moment(this.data.date, 'YYYYMMDD').format('DD.MM.YYYY'):'';
        },
        hour(){
            return this.data.start?moment(this.data.start).format('HH:mm'):'';
        }
    },
    data() {
        return {
            portrait: false,
            error: false,
            isMobile: null,
            lang: 'tr',
            contentTopMessage : {
                tr: 'Görüntülü Görüşmeyi Başlatmak İçin <b>Tıklayınız !</b>',
                en: 'To Start a Video Call <b>Click Here !</b>',
                de: 'Um Einen Videoanruf Zu Starten, <b>Klicken Sie Hier !</b>',
                ru: 'Чтобы начать видеозвонок, <b>нажмите здесь !</b>'
            }
        };
    },
    mounted() {
        const code = window.location.pathname.replace('/', '');
        this.portrait = window.matchMedia("(orientation: portrait)").matches;
        this.isMobile = window.matchMedia("only screen and (max-width: 760px)").matches;
        let browserLang = navigator.language.split('-')[0] ?? 'tr';
        if (['tr', 'en', 'de', 'ru'].indexOf(browserLang) > -1) {
            this.lang = browserLang;
        }
        if (code.length > 0) {
            this.$store
                .dispatch('linkCheck', code)
                .then(resp => {
                    if (resp) {
                        this.$loadScript('https://tokbox.com/embed/embed/ot-embed.js?embedId='+process.env.VUE_APP_TOKBOX+'&room=' + code)
                            .then(() => {
                                var DOMContentLoaded_event = document.createEvent('Event');
                                DOMContentLoaded_event.initEvent('DOMContentLoaded', true, true);
                                window.document.dispatchEvent(DOMContentLoaded_event);
                                document.querySelector(':root').style.setProperty('--vh', window.innerHeight/100 + 'px');
                                document.querySelector('#start-mask #start').innerHTML = `<h3>${this.contentTopMessage[this.lang]}</h3>${camLogo}`
                            })
                            .catch(() => {
                                console.log('script failed');
                            });
                    } else {
                        this.error = true;
                    }
                })
                .catch(err => {
                    this.error = true;
                });
        } else {
            this.error = true;
        }
    }
};
</script>

<style lang="scss">
.logo {
    z-index: 9;
    position: absolute;
    top: 0;
    left: 10px;
    max-width: 130px;
}
body {
    margin: 0px;
    background-color: #b5b5b5;
}
.error {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #b5b5b5;
    .nocontentimg {
        position: absolute;
        top: 50%;
        margin-top: -70px;
        left: 50%;
        margin-left: -70px;
        background: #363636;
        border-radius: 100%;
        padding: 20px;
    }
}
.Embed-main {
    margin: 0px !important;
    border: 0 !important;
}
.Embed-control-subscriber-container {
    bottom: 60px;
}
#otEmbedContainer {
    max-width: 100vw;
    max-height: calc(100 * var(--vh));
    margin: 0 auto;
}
#cameraPublisherContainer {
    bottom: 60px;
    top: initial;
}
.appointmentInfo .icon {
    max-width: 16px;
    display: inline-block;
    vertical-align: revert;
}
.appointmentInfo {
    font-size: 20px;
    border: 1px solid #ddd;
    background-color: #f5f5f5;
    width: 250px;
    height: 50px;
    position: absolute;
    left: 50%;
    margin-left: -125px;
    text-align: center;
    line-height: 50px;
    border-radius: 10px;
    font-weight: bold;
    top: 65%;
}
[data-wd="startCall"] {
    position: relative;
    display: block;
    h3{
        font-size: 22px;
        font-weight: normal;
    }
    text-align: center;
}

#cameraPublisherContainer {
    width: 30%;
    height: 40%;
    min-height: 160px;
}

.portraitView {
    #cameraPublisherContainer {
        height: 20%;
    }
}
</style>
