import { createStore } from 'vuex';
import apiCall from '../utils/apiCall';

export default createStore({
    state: {
        data: {
            code: null,
            date: null,
            start: null,
            end: null
        }
    },
    mutations: {
        setLinkData: (state, data) => {
            state.data = data;
        }
    },
    actions: {
        linkCheck: ({ commit }, code) => {
            return new Promise((resolve, reject) => {
                apiCall({ url: process.env.VUE_APP_BASEURL + '/appointment/linkcheck/' + code })
                    .then(resp => {
                        if (resp.appointment!==undefined) {
                            commit('setLinkData', { code, date: resp.appointment.date, start: resp.appointment.start, end: resp.appointment.end });
                        }
                        resolve(resp.status);
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        }
    },
    modules: {}
});
