import axios from 'axios';

const apiCall = (data) =>
    new Promise((resolve, reject) => {
        try {
            axios(data)
                .then(resp => {
                    resolve(resp.data);
                })
                .catch(err => {
                    reject(err);
                });
        } catch (err) {
            reject(err);
        }
    });

export default apiCall;
